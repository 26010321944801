import React from "react";
import { useState, useEffect } from "react";

const formats = [
  // { name: 'APNG', mime: 'image/apng' },
  // { name: 'AVIF', mime: 'image/avif' },
  // { name: 'BMP', mime: 'image/bmp' },
  // { name: 'GIF', mime: 'image/gif' },
  // { name: 'ICO', mime: 'image/x-icon' },
  { name: "JPEG", mime: "image/jpeg" },
  { name: "PNG", mime: "image/png" },
  // { name: 'SVG', mime: 'image/svg+xml' },
  // { name: 'TIFF', mime: 'image/tiff' },
  { name: "WebP", mime: "image/webp" },
  // { name: 'XBM', mime: 'image/xbm' }
];

const formatsByMime = {};
formats.forEach((format) => {
  formatsByMime[format.mime] = format;
});

const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result as string);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
};

function saveAs(uri, filename) {
  var link = document.createElement("a");
  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
}

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

const App = () => {
  const [sources, setSources] = useState([]);
  const [selectedFormats, setSelectedFormats] = useState([]);
  const [results, setResults] = useState([]);
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleNewFiles(e.dataTransfer.files);
    }
  };

  const createImage = (source, format) => {
    if (!source || !format) return;
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(image, 0, 0);
      canvas.toBlob(async (blob) => {
        console.log(4);
        const b = blob as Blob;
        const dataUrl = await blobToDataURL(b);
        setResults((results) => [...results, dataUrl]);
      }, format);
    };
    const sourceBlob = dataURItoBlob(source);
    image.src = URL.createObjectURL(sourceBlob);
  };

  useEffect(() => {
    setResults([]);
    sources.forEach((source) => {
      selectedFormats.forEach((format) => {
        console.log(`Creating image in ${format}`);
        createImage(source, format);
      });
    });
  }, [JSON.stringify(sources), JSON.stringify(selectedFormats)]);

  const handleNewFiles = (files) => {
    setSources([]);
    if (!files) return;
    const nextSources = [];
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        if (!e?.target?.result) return;
        img.src = e.target.result as string;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
          ctx.drawImage(img, 0, 0);
          const data = canvas.toDataURL();
          // @ts-ignore
          nextSources.push(data);
          if (nextSources.length === files.length) {
            setSources(nextSources);
          }
        };
      };
      reader.readAsDataURL(file as File);
    });
  };

  return (
    <>
      <main>
        <div className="container">
          <div className="header">
            <img
              className="logo"
              src="/src/assets/logo.png"
              alt="ConvertCamel"
            />
            <h2>
              The world's fastest way to convert as many images as you want from
              any format to any other. Instant, easy, free, and private:
              conversion happens{" "}
              <span style={{ fontWeight: "800" }}>all in your browser</span>.
              Your images <span style={{ fontWeight: "800" }}>never</span> get
              sent to a server.
            </h2>
            <p className="disclaimer">
              There aren't any limits on file size or number of files you can
              convert at once, but with gigantic files or many at once you'll
              probably run into weird bugs, maybe crash your browser, who knows!
              But I'm a website, not a cop, do whatever you want with this
              thing.
            </p>
          </div>
        </div>

        <input
          tabIndex="0"
          id="uploader"
          className="uploader"
          type="file"
          accept="image/*"
          multiple
          onChange={(e) => {
            const files = e.target.files;
            handleNewFiles(files);
          }}
        />

        <form
          id="form-file-upload"
          onDragEnter={handleDrag}
          onSubmit={(e) => e.preventDefault()}
        >
          <label htmlFor="uploader" className="source-image-area">
            {sources.length === 0 && (
              <p>
                Click here to add images,
                <br />
                or drag images here
              </p>
            )}
            <div className="source-images">
              {!!sources.length &&
                sources.map((source) => (
                  <div className="source-image">
                    <img src={source} />
                  </div>
                ))}
            </div>
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>

        {/* <div className="formats-controls">
                <button
                    className="formats-control"
                    onClick={() => {
                        const mimes = formats.map(format => format.mime);
                        setSelectedFormats(mimes);
                    }}
                >
                    Select all
                </button>
                <button
                    className="formats-control"
                    onClick={() => {
                        setSelectedFormats([]);
                    }}
                >
                    Select none
                </button>
            </div> */}

        <div>
          <fieldset className="checkbox-group">
            <legend className="checkbox-group-legend">
              Select formats to convert to
            </legend>
            {formats.map(({ name, mime }) => (
              <div className="checkbox" key={mime}>
                <label className="checkbox-wrapper" htmlFor={mime}>
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    value={mime}
                    id={mime}
                    defaultChecked={false}
                    checked={selectedFormats.includes(mime)}
                    onChange={(e) => {
                      if (selectedFormats.includes(mime)) {
                        setSelectedFormats(
                          selectedFormats.filter((format) => format !== mime)
                        );
                      } else {
                        setSelectedFormats([...selectedFormats, mime]);
                      }
                    }}
                  />
                  <span className="checkbox-tile">
                    <span className="checkbox-label">{name}</span>
                  </span>
                </label>
              </div>
            ))}
          </fieldset>
        </div>

        <button
          className="result-area"
          onClick={() => {
            if (!!results) results.forEach((r) => saveAs(r, "file"));
          }}
        >
          {results.length === 0 && <p>Click to download images</p>}
          {!!results.length &&
            results.map((result) => (
              <div className="result-image">
                <img src={result} />
              </div>
            ))}
        </button>
      </main>

      <footer>
        <p>If you liked this site, you might enjoy:</p>
        <div className="sitelinks">
          <a
            href="https://legislature.ai"
            target="_blank"
            className="sitelink"
            rel="noopener noreferrer"
          >
            <img src="/src/assets/legislatureai-min.jpg" alt="Legislature.AI" />
          </a>

          <a
            href="https://makeemoji.com"
            target="_blank"
            className="sitelink"
            rel="noopener noreferrer"
          >
            <img src="/src/assets/makeemoji.png" alt="MakeEmoji.com" />
          </a>

          <a
            href="https://ravetab.com"
            target="_blank"
            className="sitelink"
            rel="noopener noreferrer"
          >
            <img src="/src/assets/ravetab-min.jpg" alt="ravetab.com" />
          </a>
        </div>
      </footer>
    </>
  );
};
export default App;
